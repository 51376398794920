import React from "react";
import {styled} from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import {useResultTableState} from "./ResultTableContext";
import PropTypes from "prop-types";

const PREFIX = "ResultTableHead";

const classes = {
    visuallyHidden: `${PREFIX}-visuallyHidden`,
};

const StyledTableHead = styled(TableHead)(() => ({
    [`& .${classes.visuallyHidden}`]: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

/**
 * @function
 * @param {Object} props Component props
 * @param {number} props.numSelected Number of selected rows
 * @param {number} props.rowCount Number of rows in the table
 * @param {string} props.orderBy Property of the column to sort by
 * @param {string} props.order The order of sorting
 * @param {function} props.onRequestSort Function to call when a column is sorted
 * @param {function} props.onSelectAllClick Function to call when the select all checkbox is clicked
 * @param {function} props.t A translation function
 * @returns {React.Component} A table head component
 */
const ResultTableHead = ({
                             numSelected,
                             rowCount,
                             orderBy,
                             order,
                             onRequestSort,
                             onSelectAllClick,
                             t,
                         }) => {

    const {columnsToRender} = useResultTableState();

    /**
     * Creates a function that handles sorting by a given column.
     * @param {string} property The property of the column to sort by
     * @returns {function} A function that calls onRequestSort with the given property
     */
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    /**
     * Returns a sort label for a given column header cell.
     * @param {Object} headCell The column header cell
     * @returns {React.Component} A sort label component
     */
    const getSortLabel = (headCell) => {
        if (headCell.disableSorting) {
            return headCell.getLabel(t);
        }

        return (
            <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
            >
                {headCell.getLabel(t)}
                {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
                ) : null}
            </TableSortLabel>
        );
    };

    return (
        <StyledTableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{"aria-label": "select all"}}
                    />
                </TableCell>
                {columnsToRender.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {getSortLabel(headCell)}
                    </TableCell>
                ))}
            </TableRow>
        </StyledTableHead>
    );
};

ResultTableHead.propTypes = {
    "numSelected": PropTypes.number.isRequired,
    "rowCount": PropTypes.number.isRequired,
    "orderBy": PropTypes.string.isRequired,
    "order": PropTypes.string.isRequired,
    "onRequestSort": PropTypes.func.isRequired,
    "onSelectAllClick": PropTypes.func.isRequired,
    "t": PropTypes.func.isRequired
};

export {ResultTableHead};