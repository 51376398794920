import {useEffect, useState} from "react";
import {useAuthsState,} from "../auths/authsContext";
import {useSnackbarDispatch, useSnackbarTranslation,} from "../snackbar/SnackbarContext";
import {useMyMuseums} from "../museum/useMyMuseums";
import {useDmsStatusState} from "./DmsStatusContext";
import {kickOffDmsStatusWorker} from "../webworkers/kickOffDmsStatusWorker";
import {clientLog} from "../clientLog";
import {showSnackbar} from "../app/showSnackbar";

/**
 * Displays a warning or error message if DAMS storage is unavailable.
 * If a museum doesn't have a DAMS storage config, a warning message is shown.
 * If the entire DAMS storage is unavailable, an error message is shown.
 *
 * @returns {JSX.Element} A message to be rendered.
 */
export const DmsStatus = () => {

    const componentName = 'DmsStatus';

    const t = useSnackbarTranslation();

    const {userIsAuthenticated} = useAuthsState();
    const {dmsStatus} = useDmsStatusState();

    const snackbarDispatch = useSnackbarDispatch();
    const myMuseums = useMyMuseums({});

    const [dmsStatusDisplayed, setDmsStatusDisplayed] = useState({
        warning: false,
        errors: false,
    });

    /**
     * Displays an error message if DAMS storage is unavailable.
     * If the function is called, it will also prevent the warning message
     * from being shown.
     */
    const showError = () => {
        showSnackbar(snackbarDispatch,
            t("snackbarDamsErrorTitle", "DAMS feil - kontakt support"),
            t("snackbarDamsErrorMessage", "DAMS lagringsområde ikke tilgjengelig - kontakt support."),
            "error");
        setDmsStatusDisplayed({
            ...dmsStatusDisplayed,
            errors: true,
        });
    };

    /**
     * Displays a warning message if DAMS storage is unavailable for some museums.
     * If the function is called, it will also prevent the error message
     * from being shown.
     * @param {array} museumIdentifiers Identifiers of the museums for which DAMS storage is unavailable.
     */
    const showWarning = (museumIdentifiers) => {
        const message =
            t(
                "damsWarningMessage",
                "DAMS lagringsområde kan ikke nås for disse museene: "
            ) +
            museumIdentifiers?.map((m) => getMuseumNameFromIdentifier(m)).join(", ");

        showSnackbar(snackbarDispatch,
            t("snackbarDamsErrorTitle", "DAMS feil - kontakt support"),
            message,
            "warning");

        setDmsStatusDisplayed({
            ...dmsStatusDisplayed,
            warning: true,
        });
    };

    /**
     * Finds the name of the museum with the specified identifier.
     * @param {string} identifier The identifier of the museum to find.
     * @returns {string|null} The name of the museum, or null if it could not be found.
     */
    const getMuseumNameFromIdentifier = (identifier) => {
        if (!myMuseums || myMuseums.length === 0) {
            return;
        }
        return myMuseums.find((m) => m['ekultur_id'] === identifier)?.name;
    };

    useEffect(() => {
        if (userIsAuthenticated && myMuseums.length > 0) {
            kickOffDmsStatusWorker();
        }
    }, [userIsAuthenticated, myMuseums]);

    useEffect(() => {
        if (!userIsAuthenticated || !myMuseums || myMuseums.length === 0) {
            return;
        }

        // If requesting configs. fails, log the error and alert the user.
        if (dmsStatus.errorCount > 0 && !dmsStatusDisplayed.errors) {
            dmsStatus.errors.forEach(error => {
                clientLog('error', error, componentName);
            });
            clientLog('error', 'DAMS storage is unavailable - contact support', componentName);
            showError();
        }

        // If missing museum configs. nag user and log to console.
        if (dmsStatus['missingConfigs']?.length > 0 && !dmsStatusDisplayed.warning) {
            for (let i = 0, max = dmsStatus['missingConfigs'].length; i < max; i++) {
                clientLog('warn',
                    'missing config for: ' + dmsStatus['missingConfigs'][i],
                    componentName);
            }
            showWarning(dmsStatus['missingConfigs']);
        }
    });
};
