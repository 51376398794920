import {Stack, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import React from "react";
import {useField} from "formik";

/**
 * Renders a component that displays two radio buttons with the options "Overskriv" (overwrite) and "Tilføy" (merge).
 * The component is only rendered if the initial value for the given `formikKey` is not undefined.
 *
 * @param {string} formikKey - The key used by Formik to access the value of the field.
 * @param {function} callback - The callback function to be called when the user selects one of the radio buttons. The callback is passed two arguments: the value of `formikKey`, and the value of the selected radio button.
 * @param {boolean} batchEdit - Indicates if the parent form is in batch edit mode.
 * @param {JSX.Element} children - The element to be rendered next to the radio buttons.
 *
 * @returns {JSX.Element} The rendered component.
 */
export const RadioButtonOverwriteOrAdd = ({formikKey, callback, batchEdit, children}) => {
    const smallScreen = useMediaQuery("(max-width: 768px)");
    const [_, meta] = useField(formikKey);
    if (undefined === meta.initialValue) {
        return <></>;
    } else if (!batchEdit) {
        return children;
    } else if (batchEdit) {
        return <Stack direction={smallScreen ? "column" : "row"}
                      alignItems={"flex-start"}
                      sx={{
                          minWidth: '100%',
                          marginBottom: smallScreen ? '16px' : 0
                      }}>
            <Box sx={{minWidth: '70%', maxWidth: '75%'}}>
                {children}
            </Box>
            <Box sx={{marginLeft: '16px', maxWidth: '25%'}}>
                <RadioGroup
                    aria-labelledby={`${formikKey}-radio-group`}
                    defaultValue="overwrite"
                    name={`${formikKey}-radio-group`}
                    onChange={event => callback(formikKey, event.target.value)}
                >
                    <Stack direction={"row"} alignItems={"center"}>
                        <FormControlLabel value="overwrite" control={<Radio size={"small"}/>} label="Overskriv"/>
                        <FormControlLabel value="merge" control={<Radio size={"small"}/>} label="Tilføy"/>
                    </Stack>
                </RadioGroup>
            </Box>
        </Stack>;
    }
};