import React from "react";
import {DamsForm} from "../form/DamsForm";
import {usePostDocuments} from "./usePostDocuments";
import {useBatchEditState} from "./batchEditContext";
import {ADD_MESSAGE, useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {useDocumentState, useDocumentTranslation} from "./documentContext";
import useDeepCompareEffect from "use-deep-compare-effect";
import Box from "@mui/material/Box";

/**
 * Renders a form for batch editing of documents.
 *
 * @param {Object} props - The component props.
 * @param {Array<Object>} props.models - The original models to be edited.
 * @param {Function} props.onComplete - Callback function to be called when all documents are finished.
 * @param {ReactNode} props.children - The child components to be rendered inside the form.
 * @return {JSX.Element} The rendered form component.
 */
export const BatchEditForm = ({models, onComplete, children}) => {
    const t = useDocumentTranslation();
    // eslint-disable-next-line no-unused-vars
    const [postDocumentsResponse, postDocuments, postDocumentsInChunks] = usePostDocuments(); // NOSONAR
    const {selectedFields} = useBatchEditState();
    const {failed, saved} = useDocumentState();

    const snackbarDispatch = useSnackbarDispatch();

    // When operating in batch-mode, the initial values of the form fields are empty.
    // Values are appended to the existing field values, or overwrites the existing field values when a document is saved
    const initialValues = {
        title: '',
        description: '',
        remarks: '',
        productionDate: null,
        producer: [],
        persons: [],
        places: [],
        subjects: [],
        licenses: [],
        languages: [],
        relations: [],
        copyrightInfo: [],
        copyrightType: [],
        copyrightTypeDateUntil: [],
        copyrightTypeOriginator: [],
        copyrightTypeResponsible: [],
        copyrightTerms: ''
    };

    /**
     * Shows a snackbar message with the given message and an error type.
     * @param {string} message - The message to be displayed in the snackbar.
     */
    const showErrorSnackbar = (message) => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t("snackbarBatchEditError", "Lagring feilet"),
                body: message,
                type: "error",
            },
        });
    };

    /**
     * Appends copyright and licensing information to the updated models, if selected for update.
     *
     * @param {Object} newValues - The new values to be appended to the existing models.
     * @param {Array<Object>} updatedModels - The updated models to be modified.
     * @param {number} n - The index of the current model in the updatedModels array.
     */
    const appendCopyrightAndLicensingInformation = (newValues, updatedModels, n) => {
        /**
         * Appends copyright information to the updated model.
         *
         * If the newValues.copyrightInfo array is not empty, the content of the
         * array is appended to the updated model's copyrightInfo array.
         * Additionally, the responsible persons specified in the newValues object
         * are added to the corresponding index in the updated model's copyrightInfo
         * array.
         */
        function appendCopyrightInfo() {
            if (newValues.copyrightInfo && newValues.copyrightInfo.length > 0) {
                updatedModels[n].content.copyrightInfo = newValues.copyrightInfo;

                // Append copyright info responsible,
                // as this is stored in a parallel structure in the newValues object:
                const i = newValues.copyrightInfo.length;
                for (let j = 0; j < i; j++) {
                    const responsible = newValues[`copyrightResponsible${j}`];
                    if (responsible) {
                        updatedModels[n].content.copyrightInfo[j].responsible = responsible;
                    }
                }
            }
        }

        /**
         * Appends copyright type information to the updated models, if selected for update.
         *
         * The following fields are updated, if the corresponding newValues field is non-empty:
         * - copyrightType
         * - copyrightTypeDateUntil
         * - copyrightTypeOriginator
         * - copyrightTypeResponsible
         */
        function appendCopyrightType() {
            if (newValues.copyrightType && newValues.copyrightType !== '') {
                updatedModels[n].content.copyrightType = newValues.copyrightType;
            }
            if (newValues.copyrightTypeDateUntil && newValues.copyrightTypeDateUntil !== '') {
                updatedModels[n].content.copyrightTypeDateUntil = newValues.copyrightTypeDateUntil;
            }
            if (newValues.copyrightTypeOriginator && newValues.copyrightTypeOriginator.length > 0) {
                updatedModels[n].content.copyrightTypeOriginator = newValues.copyrightTypeOriginator;
            }
            if (newValues.copyrightTypeResponsible && newValues.copyrightTypeResponsible.length > 0) {
                updatedModels[n].content.copyrightTypeResponsible = newValues.copyrightTypeResponsible;
            }
        }

        if (selectedFields.find(f => f.name === 'copyrightAndLicensing')) {
            // Append license information, if it is selected for update.
            // NOTE: These fields are not eligible for overwrite or merge.
            if (newValues.licenses && newValues.licenses.length > 0) {
                updatedModels[n].content.licenses = newValues.licenses;
            }
            appendCopyrightType();
            appendCopyrightInfo();
        }
    };

    /**
     * Merges two arrays of objects, `existing` and `newValues`, to avoid duplicates.
     * The merge is done by checking if the `title` property of the `reference` property of each object
     * in `newValues` is already present in `existing`. If it is not, the object is added to the output array.
     * The output array is then returned.
     * @param {number} collectionId - The ID of the collection to filter by.
     * @param {Array} existing - The existing array of objects to be merged.
     * @param {Array} newValues - The new array of objects to be merged.
     * @returns {Array} The merged array of objects.
     */
    const checkForDuplicateArrayValues = (collectionId, existing, newValues) => {
        const newValuesByCollectionId = newValues.filter(nv => nv.collectionId === collectionId);
        let output = [...existing];
        const existingValues = [...existing].map(e => e.reference.title);
        for (let j = 0, max = newValuesByCollectionId.length; j < max; j++) {
            if (!existingValues.includes(newValuesByCollectionId[j].title)) {
                output.push(newValuesByCollectionId[j]);
            }
        }
        return output;
    };


    /**
     * Utility function that merges the new field value into the existing field values.
     *
     * The merging rules are as follows:
     * - If the existing field value is null or undefined, set it to the new value.
     * - If the new value is not an array, append the new value to the existing field value.
     * - If the new value is an array and contains values with the same collectionId as the existing field value,
     *   append the new values to the existing field value, without duplicates, based on the 'title' property.
     *
     * @param {Number} collectionId - The collectionId of the existing field value.
     * @param {Any} modelField - The existing field value.
     * @param {Any} newValue - The new field value to be merged.
     * @return {Any} The resulting merged field value.
     */
    const mergeFieldValues = (collectionId, modelField, newValue) => {
        if (modelField === null || typeof (modelField) === 'undefined') {
            if (Array.isArray(newValue)) {
                modelField = newValue.filter(nv => nv.collectionId === collectionId);
            } else {
                modelField = newValue;
            }
        } else {
            if (!Array.isArray(newValue)) {
                modelField = `${modelField} ${newValue}`;
            } else if (Array.isArray(newValue)) {
                modelField = checkForDuplicateArrayValues(collectionId, modelField, newValue);
            }
        }
        return modelField;
    };

    /**
     * Takes a merge or overwrite function name, an existing field value, the collectionId of the existing field value,
     * and a new field value, and applies the specified merge or overwrite operation on the existing field value.
     * The rules for merging and overwriting are as follows:
     * - If the function name is 'merge', the new field value is merged with the existing field value.
     *   The merging rules are as follows:
     *   - If the existing field value is null or undefined, set it to the new value.
     *   - If the new value is not an array, append the new value to the existing field value.
     *   - If the new value is an array and contains values with the same collectionId as the existing field value,
     *     append the new values to the existing field value, without duplicates, based on the 'title' property.
     * - If the function name is 'overwrite', the new field value overwrites the existing field value.
     *   The overwriting rules are as follows:
     *   - If the new value is not an array, overwrite the existing field value with the new value.
     *   - If the new value is an array, overwrite the existing field value with the new array, filtered by the collectionId.
     *
     * @param {string} fn - The name of the merge or overwrite function to apply.
     * @param {Any} modelField - The existing field value.
     * @param {Number} collectionId - The collectionId of the existing field value.
     * @param {Any} newValue - The new field value to be merged or overwritten.
     * @return {Any} The resulting merged or overwritten field value.
     */
    const overwriteOrMergeValue = (fn, modelField, collectionId, newValue) => {
        if (fn === 'merge') {
            modelField = mergeFieldValues(collectionId, modelField, newValue);
        } else if (fn === 'overwrite') {
            if (Array.isArray(newValue)) {
                modelField = newValue.filter(nv => nv.collectionId === collectionId);
            } else {
                modelField = newValue;
            }
        }
        return modelField;
    };

    /**
     * Takes the new values and merges them with the existing models.
     * @param {Object} newValues - The new values to be merged into the models.
     * @returns {Array} The updated models.
     */
    const getUpdatedModels = (newValues) => {
        let updatedModels = [...models];
        for (let n = 0, max = updatedModels.length; n < max; n++) {
            const model = updatedModels[n];
            const {collectionId} = model;
            for (let i = 0, max = selectedFields.length; i < max; i++) {
                const {name, fn} = selectedFields[i];
                const newValue = newValues[name];
                let modelField = (name === 'title' || name === 'description') ? model[name] : model.content[name];

                modelField = overwriteOrMergeValue(fn, modelField, collectionId, newValue);

                // Update the model's field with the new value:
                if (name === 'title' || name === 'description') {
                    model[name] = modelField;
                } else {
                    model.content[name] = modelField;
                }
            } // end: selectedFields loop

            appendCopyrightAndLicensingInformation(newValues, updatedModels, n);
        } // end: models-loop
        return updatedModels;
    };

    /**
     * The callback function for the form submission.
     * It takes the new values and merges them with the existing models,
     * and then sends the updated models to the server using `postDocumentsInChunks`.
     * If there are any failed documents, it shows an error snackbar.
     * @param {Object} newValues - The new values to be merged into the models.
     */
    const onSubmit = (newValues) => {
        let updatedModels = getUpdatedModels(newValues);
        postDocumentsInChunks(updatedModels).then(() => {
            if (postDocumentsResponse.failedDocuments["length"] > 0) {
                showErrorSnackbar(
                    t(
                        "snackbarBatchEditErrorMsg",
                        "Lagring feilet for en eller flere dokumenter. Disse vil fortsatt være avkrysset/merket."
                    )
                );
            }
        });
    };

    /**
     * Hook used to append licensing and copyright fields.
     */
    useDeepCompareEffect(() => {
        if (failed.length === 0 && saved.length === 0) {
            return;
        }
        if (failed.length + saved.length === models.length) {
            // Await complete-state until all documents are finished
            onComplete(saved, failed);
        }
    }, [saved, failed]);

    const filteredInitialValues = selectedFields.reduce(
        (acc, field) => ({
            ...acc,
            [field.name]: initialValues[field.name],
        }),
        {}
    );

    // If "copyrightAndLicensing" is selected, add all files related to copyright/licencing,
    // as these are not the result of selecting an individual field, but a group of fields.
    // NOTE: Each field is added as an empty array, as fields are "cleared" when batch editing objects.
    if (Boolean(selectedFields.find(f => f.name === 'copyrightAndLicensing'))) {
        filteredInitialValues.licenses = [];
        filteredInitialValues.copyrightType = [];
        filteredInitialValues.copyrightTypeDateUntil = [];
        filteredInitialValues.copyrightTypeResponsible = [];
        filteredInitialValues.copyrightTypeOriginator = [];
    }

    return <DamsForm initialValues={filteredInitialValues} onSubmit={onSubmit}>
        <Box sx={{
            minWidth: {
                xs: '80vw',
                sm: '80vw',
                md: '80vw',
                lg: '50vw',
                xl: '50vw',
            },
        }}>
            {children}
        </Box>
    </DamsForm>;
};
